/*eslint-disable*/
import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import {
  IBusinessPlanFormBaseLayout,
  IGrantFormBaseLayout,
} from './GrantForm.interfaces';
import OnboardProgress from './components/OnboardingProgress';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import MainButton from '@/components/buttons/Buttons';
import { TextField } from '@/components/forms';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import FormLoader from './components/FormLoader';
import OnboardProgressBusinessPlan from './components/OnboardingProgressBusinessPlan';
import { getPreviousBusinessPlanRoute } from './services/GrantFormBaseLayout.service';

const CustomTextField = styled(TextField)`
  margin-bottom: 70px !important;
`;

const BusinessFormBaseLayout = ({
  onSubmit,
  handleSubmit,
  isLoading,
  formStage,
  preview,
  print,
  showBottomButtons = true,
  ...props
}: IBusinessPlanFormBaseLayout) => {
  const navigate = useNavigate();
  const nullNext = () => {};
  return (
    <>
      {print === true && (
        <TextField
          text={props.title}
          className={'fs-5 fw-bold mb-5 mt-5 text-center'}
        />
      )}
      {preview == true && (
        <>{isLoading == true ? <FormLoader /> : props.children}</>
      )}
      {!preview && (
        <Container style={{ padding: '20px' }}>
          <ToastContainer />
          <Row style={{ marginTop: '20px', marginBottom: '40px' }}>
            <Col>
              <div className="px-4 float-end fw-bold">
                <Link to="/dashboard" className="text-decoration-none">
                  <MainButton
                    text={'Save and Continue Later'}
                    variant="outline-primary"
                    size="md"
                  />
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={3}>
              <OnboardProgressBusinessPlan page={props.page} />
            </Col>

            <Col md={9} lg={9} xs={12} sm={12}>
              <form onSubmit={handleSubmit?.(onSubmit)}>
                <TextField
                  text={'Business Plan Form for Startups and Micro Enterprises'}
                  className={'fs-5 fw-bold mb-5 text-center'}
                />
                <CustomTextField
                  text={props?.title || ''}
                  className={'fs-5 fw-bold mb-5 text-center'}
                  fontSize={12}
                />
                {isLoading == true && <FormLoader />}
                {!isLoading && <>{props.children}</>}
                {props.page !== 7 && showBottomButtons == true && (
                  <Row className="mt-5">
                    <Col md={4} xs={12} style={{ marginBottom: '10px' }}>
                      <MainButton
                        text={'Previous'}
                        type={'button'}
                        variant="outline-secondary"
                        size="lg"
                        onClick={() => {
                          if (
                            getPreviousBusinessPlanRoute({
                              stage: formStage,
                            }) == 'dashboard'
                          ) {
                            navigate('/dashboard');
                          } else
                            navigate(
                              `/grants/businessplan/${getPreviousBusinessPlanRoute(
                                { stage: formStage }
                              )}`
                            );
                        }}
                      />
                    </Col>
                    <Col
                      md={{ span: 4, offset: 4 }}
                      xs={12}
                      style={{ marginBottom: '10px' }}
                    >
                      <MainButton
                        text={formStage == 'consent' ? 'Submit Form' : 'Next'}
                        type={onSubmit ? 'submit' : 'button'}
                        variant="primary"
                        size="lg"
                        isLoading={isLoading}
                        isDisabled={props?.isDisabled || false}
                        onClick={props?.onNext || nullNext}
                      />
                    </Col>
                  </Row>
                )}
              </form>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default BusinessFormBaseLayout;
