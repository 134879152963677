import React, { Fragment } from "react";
import { Col, Image, Row } from "react-bootstrap";
import successImage from '@images/logo/Success.svg'
import { TextField } from "@components/forms";
import MainButton from "@components/buttons/Buttons";
import { Link } from "react-router-dom";


const SuccessPage = () =>{
    
    return(
        <Fragment>
            <Col md={12} className="mt-5">
                <div className="d-flex justify-content-center align-items-center h-100 text-center">
                    <Row >
                        <Col md={12}><Image src={successImage} style={{width:"18rem"}}/></Col>
                        <Col md={12}><TextField text={'Your application has been successfully submitted'} className={'fs-6 fw-bold mb-5 mt-5'}/></Col>
                       
                        <Col md={12} className="d-flex justify-content-center align-items-center mb-4">
                            <Col md={6}>
                                <Link to="/dashboard">
                                    <MainButton text="Return To Dashboard" className="text-center"/>
                                </Link>
                            </Col>
                        </Col>
                        <Col md={12} className="d-flex justify-content-center align-items-center">
                            <Col md={6}>
                                <Link to="/onboarding/application_pdf" target="_blank" className="text-decoration-none">
                                    <MainButton text="Download Application" variant="outline-primary" className="text-center"/>
                                </Link>
                            </Col>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Fragment>
    )
}

export default SuccessPage