import React, { Fragment, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import propTypes from 'prop-types';
import Login from '@views/Login/LoginPage';
import GetStarted from '@views/Login/GetStartedPage';
import Signup from '@views/Signup/SignUpPage';
import './App.css';
import StaticPagesBaseLayout from '@components/layout/layouts/StaticPagesBaseLayout/StaticPagesBaseLayout';
import { HomePage } from './views';
import TermsAndConditions from '@views/Terms/TermsAndConditions';
import FAQ from '@views/Faqs/FAQ';
import SignUpSuccess from '@views/Signup/SignUpSuccessPage';
import DashboardBaseLayout from '@components/layout/layouts/DashboardBaseLayout.js/DashboardBaseLayout';
import Home from '@views/Dashboard/Home/Home';
import { ToastContainer } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import PersonalInfo from '@views/Signup/Onboarding/PersonalInfoPage';
import LoanHome from '@views/Dashboard/Loan/Home';
import BusinessInfo from '@views/Dashboard/Loan/LoanApplication/BusinessInfo';
import BusinessLocation from '@views/Dashboard/Loan/LoanApplication/BusinessLocation';
import BusinessFinancialInfo from '@views/Dashboard/Loan/LoanApplication/BusinessFinancialInfo';
import BusinessSupportHistoryInfo from '@views/Dashboard/Loan/LoanApplication/BusinessSupportHistory';
import BusinessFirstGuarantor from '@views/Dashboard/Loan/LoanApplication/BusinessGuarantorInfo1';
import BusinessSecondGuarantor from '@views/Dashboard/Loan/LoanApplication/BusinessGuarantorInfo2';
import BusinessDeclaration from '@views/Dashboard/Loan/LoanApplication/BusinessDeclaration';
import LoanSuccess from '@views/Dashboard/Loan/LoanApplication/LoanSuccess';
import { refreshToken } from './utils/helpers/functions';
import LoanApplicationBaseLayout from '@components/layout/layouts/LoanApplicationLayout/LoanApplicationBaseLayout';
import { QueryClient, QueryClientProvider } from 'react-query';
import OnboardLayout from '@components/layout/OnboardLayout';
import EducationalInfo from '@views/Signup/Onboarding/EducationInfoPage';
import TrainingInfo from '@views/Signup/Onboarding/TraningInfoPage';
import BusniessAcumen from '@views/Signup/Onboarding/BusinessAcumenPage';
import OtherInfo from '@views/Signup/Onboarding/OtherInfoPage';
import ApplicationTerms from '@views/Signup/Onboarding/ApplicationTermsPage';
import LoanPayment from '@views/Dashboard/LoanPayment/LoanPayment';
import Verification from '@views/Signup/Onboarding/Verification';
import SuccessPage from '@components/forms/Auth/SuccessPage';
import ApplicationPDF from '@views/Signup/Onboarding/ApplicationPDFPage';
import FinancialLiteracyPage from '@views/Signup/Onboarding/FinancialLiteracyPage';
import Training from '@views/Dashboard/Training/Training';
import About from '@views/About/About';
import AboutGea from '@views/AboutGea/AboutGea';
import UserProfile from '@views/Dashboard/Profile/Profile';
import Resend from '@components/forms/Auth/Resend';
import Deadline from '@components/misc/Deadline';
import GrantsPersonalInformation from './views/GrantForm/components/forms/GrantForm/GrantsPersonalInformation';
import GrantsPersonalInformation2 from './views/GrantForm/components/forms/GrantForm/GrantsPersonalInformation2';
import BusinessForm from './views/GrantForm/components/forms/GrantForm/BusinessForm';
import ProductInformationForm from './views/GrantForm/components/forms/GrantForm/ProductionInformationForm';
import BusinessForm2 from './views/GrantForm/components/forms/GrantForm/BusinessForm2';
import ProductInformation2 from './views/GrantForm/components/forms/GrantForm/ProductInformation2';
import TechnicalCapacity from './views/GrantForm/components/forms/GrantForm/TechnicalCapacity';
import ProductMarketing from './views/GrantForm/components/forms/GrantForm/ProductMarketing';
import RevenueGeneration from './views/GrantForm/components/forms/GrantForm/RevenueGeneration';
import RevenueGeneration2 from './views/GrantForm/components/forms/GrantForm/RevenueGeneration2';
import FundingRequirement from './views/GrantForm/components/forms/GrantForm/FundingRequirement';
import ImpactForm from './views/GrantForm/components/forms/GrantForm/ImpactForm';
import ImpactForm2 from './views/GrantForm/components/forms/GrantForm/ImpactForm2';
import ApplicationSummary from './views/GrantForm/components/forms/BusinessPlan/ApplicationSummary';
import ApplicantInformation from './views/GrantForm/components/forms/BusinessPlan/ApplicantInformation';
import ApplicantInformation2 from './views/GrantForm/components/forms/BusinessPlan/ApplicantInformation2';
import ApplicantInformation3 from './views/GrantForm/components/forms/BusinessPlan/ApplicantInformation3';
import ApplicantInformation4 from './views/GrantForm/components/forms/BusinessPlan/ApplicantInformation4';
import ProjectInformation from './views/GrantForm/components/forms/BusinessPlan/ProjectInformation';
import ProjectInformation2 from './views/GrantForm/components/forms/BusinessPlan/ProjectInformation2';
import ProjectInformation3 from './views/GrantForm/components/forms/BusinessPlan/ProjectInformation3';
import ProjectInformation4 from './views/GrantForm/components/forms/BusinessPlan/ProjectInformation4';
import GrantRequest from './views/GrantForm/components/forms/BusinessPlan/GrantRequest';
import GrantRequest2 from './views/GrantForm/components/forms/BusinessPlan/GrantRequest2';
import ExpectedResults from './views/GrantForm/components/forms/BusinessPlan/ExpectedResults';
import Sustainability from './views/GrantForm/components/forms/BusinessPlan/Sustainability';
import Signature from './views/GrantForm/components/forms/BusinessPlan/Signature';
import Disclaimer from './views/GrantForm/components/forms/BusinessPlan/Disclaimer';
import PreviewGrantForm from './views/GrantForm/PreviewGrantForm';
import PreviewBusinessPlanForm from './views/GrantForm/PreviewBusinessPlanForm';
import GuidanceNote from './views/GrantForm/components/forms/BusinessPlan/GuidanceNote';
import Consent from './views/GrantForm/components/forms/BusinessPlan/Consent';
import SuccessPageBusinessPlan from './views/GrantForm/components/SuccessPageBusinessPlan';
import constant from './utils/constants/constants';

function App() {
  function RequireAuth({ children }) {
    const [refresh, setRefresh] = useState(1);
    useEffect(() => {
      refreshToken();
    }, [refresh]);
    setTimeout(function () {
      setRefresh(refresh + 1);
    }, 240000);

    let location = useLocation();
    const user = JSON.parse(window.localStorage.getItem('user'));

    if (!user) {
      return <Navigate to="/" state={{ from: location }} />;
    }
    return children;
  }

  function BusinessPlanBaseRoute({ children }) {
    const user = JSON.parse(window.localStorage.getItem('user'));
    let showBpForms = false;
    let location = useLocation();

    if (user.skipped_advanced_training == false) {
      return <Navigate to="/dashboard" state={{ from: location }} />;
    }
    user.approvals.forEach((approval) => {
      if (approval?.approval_type == 'advanced_training_approval') {
        showBpForms = true;
      }
    });

    if ((showBpForms && constant.SHOW_BUSINESS_FORM) == false) {
      return <Navigate to="/dashboard" state={{ from: location }} />;
    }

    console.log(user.profile.is_business_plan_application_complete, 1255);
    if (user.profile.is_business_plan_application_complete == true) {
      return <Navigate to="/dashboard" state={{ from: location }} />;
    }

    return children;
  }

  BusinessPlanBaseRoute.propTypes = {
    children: propTypes.node,
  };

  RequireAuth.propTypes = {
    children: propTypes.node,
  };

  const queryClient = new QueryClient();

  return (
    <Fragment>
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/deadline" element={<Deadline />} />
            <Route path="/login" element={<Login />} />
            <Route path="/get_started" element={<GetStarted />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/resend/:id" element={<Resend />} />

            <Route
              path="/signup/personal_info"
              element={
                <RequireAuth>
                  <PersonalInfo />
                </RequireAuth>
              }
            />
            <Route path="/signup/success" element={<SignUpSuccess />} />
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <DashboardBaseLayout />
                </RequireAuth>
              }
            >
              <Route index element={<Home />} />
              <Route
                path="/dashboard/grant_payment"
                element={<LoanPayment />}
              />
              <Route path="/dashboard/training" element={<Training />} />
              <Route path="/dashboard/profile" element={<UserProfile />} />
            </Route>
            <Route
              path="/loan_application"
              element={
                <RequireAuth>
                  <LoanApplicationBaseLayout />
                </RequireAuth>
              }
            >
              <Route index element={<LoanHome />} />
              <Route
                path="/loan_application/business_info"
                element={<BusinessInfo />}
              />
              <Route
                path="/loan_application/business_location"
                element={<BusinessLocation />}
              />
              <Route
                path="/loan_application/business_financial_info"
                element={<BusinessFinancialInfo />}
              />
              <Route
                path="/loan_application/business_support_history"
                element={<BusinessSupportHistoryInfo />}
              />
              <Route
                path="/loan_application/business_guarantor_1"
                element={<BusinessFirstGuarantor />}
              />
              <Route
                path="/loan_application/business_guarantor_2"
                element={<BusinessSecondGuarantor />}
              />
              <Route
                path="/loan_application/business_declaration"
                element={<BusinessDeclaration />}
              />
              <Route
                path="/loan_application/success"
                element={<LoanSuccess />}
              />
            </Route>
            <Route
              path="/onboarding"
              element={
                <RequireAuth>
                  <OnboardLayout />
                </RequireAuth>
              }
            >
              <Route index element={<PersonalInfo />} />
              <Route
                path="/onboarding/personal_information"
                element={<PersonalInfo />}
              />
              <Route
                path="/onboarding/education"
                element={<EducationalInfo />}
              />
              <Route path="/onboarding/training" element={<TrainingInfo />} />
              <Route path="/onboarding/accumen" element={<BusniessAcumen />} />
              <Route
                path="/onboarding/financial_literacy"
                element={<FinancialLiteracyPage />}
              />
              <Route
                path="/onboarding/other_information"
                element={<OtherInfo />}
              />
              <Route
                path="/onboarding/verification"
                element={<Verification />}
              />
              <Route
                path="/onboarding/application_terms"
                element={<ApplicationTerms />}
              />
              <Route path="/onboarding/success" element={<SuccessPage />} />
              <Route
                path="/onboarding/application_pdf"
                element={<ApplicationPDF />}
              />
            </Route>
            <Route
              path="/grants/personal_information"
              element={
                <RequireAuth>
                  <GrantsPersonalInformation />
                </RequireAuth>
              }
            />
            <Route
              path="/grants/personal_information_2"
              element={<GrantsPersonalInformation2 />}
            />
            <Route
              path="/grants/business_form"
              element={
                <RequireAuth>
                  <BusinessForm />
                </RequireAuth>
              }
            />
            <Route
              path="/grants/business_form_2"
              element={
                <RequireAuth>
                  <BusinessForm2 />
                </RequireAuth>
              }
            />
            <Route
              path="/grants/product_information"
              element={
                <RequireAuth>
                  <ProductInformationForm />
                </RequireAuth>
              }
            />
            <Route
              path="/grants/product_information_2"
              element={
                <RequireAuth>
                  <ProductInformation2 />
                </RequireAuth>
              }
            />
            <Route
              path="/grants/technical_capacity"
              element={
                <RequireAuth>
                  <TechnicalCapacity />
                </RequireAuth>
              }
            />
            <Route
              path="/grants/product_marketing"
              element={
                <RequireAuth>
                  <ProductMarketing />
                </RequireAuth>
              }
            />
            <Route
              path="/grants/revenue_generation"
              element={
                <RequireAuth>
                  <RevenueGeneration />
                </RequireAuth>
              }
            />
            <Route
              path="/grants/revenue_generation_2"
              element={
                <RequireAuth>
                  <RevenueGeneration2 />
                </RequireAuth>
              }
            />
            <Route
              path="/grants/funding_requirements"
              element={
                <RequireAuth>
                  <FundingRequirement />
                </RequireAuth>
              }
            />
            <Route
              path="/grants/impact"
              element={
                <RequireAuth>
                  <ImpactForm />
                </RequireAuth>
              }
            />
            <Route
              path="/grants/impact2"
              element={
                <RequireAuth>
                  <ImpactForm2 />
                </RequireAuth>
              }
            />
            <Route
              path="/grants/preview"
              element={
                <RequireAuth>
                  <PreviewGrantForm />
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/guidance"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <GuidanceNote />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/application_summary"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <ApplicationSummary />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/application_information"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <ApplicantInformation />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/application_information_2"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <ApplicantInformation2 />
                  </BusinessPlanBaseRoute>{' '}
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/application_information_3"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <ApplicantInformation3 />
                  </BusinessPlanBaseRoute>{' '}
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/application_information_4"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <ApplicantInformation4 />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/project_information"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <ProjectInformation />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/project_information_2"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <ProjectInformation2 />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/project_information_3"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <ProjectInformation3 />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/project_information_4"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <ProjectInformation4 />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/grant_request"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <GrantRequest />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/grant_request_2"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <GrantRequest2 />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/expected_result"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <ExpectedResults />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />

            <Route
              path="/grants/businessplan/sustainability"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <Sustainability />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />

            <Route
              path="/grants/businessplan/preview"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <PreviewBusinessPlanForm />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/disclaimer"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <Disclaimer />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/signature"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <Signature />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/consent"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <Consent />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />
            <Route
              path="/grants/businessplan/success"
              element={
                <RequireAuth>
                  <BusinessPlanBaseRoute>
                    <SuccessPageBusinessPlan />
                  </BusinessPlanBaseRoute>
                </RequireAuth>
              }
            />

            <Route path="/" element={<StaticPagesBaseLayout />}>
              <Route index element={<HomePage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/about" element={<About />} />
              <Route path="/terms" element={<TermsAndConditions />} />
              <Route path="/faqs" element={<FAQ />} />
              <Route path="/about" element={<About />} />
              <Route path="/about-gea" element={<AboutGea />} />
            </Route>
            <Route
              path="*"
              element={
                <main style={{ padding: '1rem' }}>
                  <p>Sorry, there is nothing here</p>
                </main>
              }
            />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </Fragment>
  );
}

export default App;
