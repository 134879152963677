/*eslint-disable*/
import React from 'react';
import { TableRoot } from './styledcomponents/forminputtable';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';

interface IFormTableProps {
  title: string;
  columns: string[];
  rows: any[][];
  options: MUIDataTableOptions;
}

const FormInputTable = ({
  columns,
  rows,
  options,
  title,
  ...props
}: IFormTableProps) => {
  return (
    <TableRoot>
      <MUIDataTable
        title={title}
        columns={columns}
        data={rows}
        options={options}
      />
    </TableRoot>
  );
};

export default FormInputTable;
