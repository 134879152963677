/*eslint-disable */
import { OptionType } from '@/utils/constants/globals.interfaces';
import React, { ChangeEvent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  IGrantExpenditureEditParams,
  IGrantExpenditureForm,
  IGrantExpenditureListResponse,
  IGrantExpenditureParams,
  IGrantUpdateParams,
} from '../../GrantForm.interfaces';
import {
  logError,
  useGetGrantExpenditureList,
  useGetUserObject,
} from '../GrantFormBaseLayout.service';
import {
  IGrantFormModel,
  IUserModel,
} from '@/utils/constants/userModel.interface';
import { AxiosResponse } from 'axios';
import {
  createGrantBusinessExpenditure,
  deleteGrantBusinessExpenditure,
  updateGrantBusinessExpenditure,
  updateGrantInformation,
} from '@/api/User/User';
import { useNavigate } from 'react-router-dom';
import { messageAlert } from '@/utils/helpers/functions';

export const useGetGrantRequest = () => {
  const { control, watch, handleSubmit, formState } = useForm();
  const {
    control: itemControl,
    reset,
    setValue,
    watch: itemWatch,
    handleSubmit: handleItemSubmit,
  } = useForm();
  const navigate = useNavigate();
  const { userData, userError, userLoading, refetch, isRefetching } =
    useGetUserObject();
  const {
    expenditureList,
    expenditureError,
    expenditureLoading,
    expenditureRefetching,
    refetchExpenditure,
  } = useGetGrantExpenditureList();
  const _userData = userData as AxiosResponse<Partial<IUserModel>>;
  const _expenditureList = expenditureList as AxiosResponse<
    IGrantExpenditureListResponse[]
  >;
  const [isFileUploading, setIsFileUploading] = React.useState(false);
  const [file, setFileObj] = React.useState<File[] | undefined>([]);
  const onSubmit = async (data: any) => {
    try {
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      grantParams.data = {
        planned_total_expenditure: data.total_expected_expenditure,
      };
      if (
        getTotalExpenditures(expenditureList?.data) !==
        parseInt(
          _userData?.data?.profile?.grant_information?.business_plan
            ?.total_funding_requested
        )
      ) {
        console.log(
          data.total_expected_expenditure,
          _userData?.data?.profile?.grant_information?.business_plan
            ?.total_funding_requested
        );
        messageAlert(
          'error',
          `The Total Expected Expenditure specified here should be equal to the Total Grant Funding you requested earlier in the form. Hint: The total requested value was GHC ${
            _userData?.data?.profile?.grant_information?.business_plan?.total_funding_requested?.toLocaleString(
              undefined,
              { maximumFractionDigits: 1 }
            ) || 0
          }`
        );
        return;
      }
      await updateGrantInformation(grantParams);
      navigate('/grants/businessplan/grant_request_2');
    } catch (e) {
      logError(e);
    }
  };

  const getTrainingDescriptionPlaceholder = (type?: string) => {
    if (type) {
      switch (type) {
        case 'capital_expenditure':
          return 'eg: Purchase of Tractor';
        case 'raw_materials':
          return 'eg: Purchase of Rice';
        case 'rent':
          return 'eg: Rental of Office Space';
        case 'others':
          return 'eg: Other Expenditure Description';
      }
    }
    return 'Expenditure Item Description';
  };
  const onItemSubmit: SubmitHandler<IGrantExpenditureForm> = async (data) => {
    const total_expenditure =
      (parseInt(data.unit_cost) || 0) * (parseInt(data.item_quantity) || 0);
    try {
      if (file.length > 5) {
        messageAlert('error', 'Maximum of 5 files permitted for upload.');
        return;
      }
      // if (file.length == 0 && data.expenditure_type == 'raw_materials') {
      //   messageAlert('error', 'Upload a file to continue');
      //   return;
      // }
      // if (
      //   total_expenditure > 10000 &&
      //   data.expenditure_type == 'capital_expenditure'
      // ) {
      //   if (file.length !== 3) {
      //     messageAlert('error', 'Must upload 3 files to continue');
      //     return;
      //   }
      // }
      setIsFileUploading(true);
      const formData = new FormData();
      formData.append('item', data.item_name);
      formData.append('quantity', data.item_quantity);
      formData.append('unit_cost', data.unit_cost);
      formData.append(
        'total_amount_spent',
        (
          (parseInt(data.unit_cost) || 0) * (parseInt(data.item_quantity) || 0)
        ).toString()
      );
      formData.append('expenditure_type', data.expenditure_type);
      if (file.length > 0) {
        file.forEach((file) => {
          if (file) {
            formData.append('invoices', file);
          }
        });
      }

      formData.append(
        'business',
        _userData?.data?.profile?.grant_information?.id.toString()
      );
      await createGrantBusinessExpenditure(formData);
      refetchExpenditure();
      reset({ item_name: '', item_quantity: '', unit_cost: '', amount: '' });
      setValue('item_name', '');
      setValue('item_quantity', '');
      setValue('unit_cost', '');
      setValue('amount', '');
      setFileObj([]);
    } catch (e) {
      logError(e);
      setIsFileUploading(false);
      return;
    }
    messageAlert('success', 'Expenditure successfully created');
    setIsFileUploading(false);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.[0]) {
      setFileObj([...file, ...e?.target?.files]);
    }
  };
  const onExpenditureDelete = async (id?: number | string) => {
    setIsFileUploading(true);
    try {
      await deleteGrantBusinessExpenditure(id);
      refetchExpenditure();
    } catch (e) {
      logError(e);
      return;
    }
    messageAlert('success', 'Expenditure successfully deleted');
    setIsFileUploading(false);
  };

  const onExpenditureEdit = async ({
    id,
    item,
    quantity,
    total_amount_spent,
    unit_cost,
  }: IGrantExpenditureEditParams) => {
    setIsFileUploading(true);
    try {
      await updateGrantBusinessExpenditure(id, {
        item: item,
        quantity: quantity,
        total_amount_spent: quantity * parseInt(unit_cost),
        unit_cost: unit_cost,
      });
    } catch (e) {
      logError(e);
    }
    refetch();
    refetchExpenditure();
    setIsFileUploading(false);
  };

  const getTotalExpenditures = (
    expenditureArray: IGrantExpenditureListResponse[]
  ) => {
    try {
      const totalExpenditure = expenditureArray.reduce(
        (accumulator, previousValue) => {
          return (accumulator =
            accumulator + (parseInt(previousValue.total_amount_spent) || 0));
        },
        0
      );
      return totalExpenditure;
    } catch (e) {
      return 0;
    }
  };

  const clearFiles = () => {
    setFileObj([]);
  };

  const clearFile = (searchIndex: number) => {
    let newArray = file.filter((value, index) => {
      if (index != searchIndex) {
        return value;
      }
    });

    setFileObj(newArray);
  };

  const loadTableData = (
    rowData: IUserModel['profile']['grant_information']['business_expenditures']
  ) => {
    try {
      let rows: any[][] = [];
      let totalAmount = 0;
      let totalQuantity = 0;
      let totalUnitCost = 0;

      rowData?.forEach((row) => {
        rows.push([
          row.item,
          row.quantity,
          row.unit_cost,
          row.total_amount_spent,
        ]);
        totalQuantity = totalQuantity + row.quantity;
        totalUnitCost = totalUnitCost + parseInt(row.unit_cost);
        totalAmount = totalAmount + parseInt(row.total_amount_spent);
      });

      rows.push([
        'Total Expected Expenditure',
        totalQuantity,
        totalUnitCost,
        totalAmount,
      ]);
      return rows;
    } catch (e) {
      return [];
    }
  };

  return {
    file,
    userData: _userData,
    userLoading: userLoading || expenditureLoading,
    isRefetchingExpenditure: expenditureRefetching,
    isFileUploading: isFileUploading,
    control,
    formState,
    itemControl,
    expenditureList: _expenditureList,
    expenditureLoading,
    clearFile,
    getTrainingDescriptionPlaceholder,
    loadTableData,
    getTotalExpenditures,
    clearFiles,
    watch,
    handleSubmit,
    onSubmit,
    itemWatch,
    handleItemSubmit,
    onItemSubmit,
    handleFileChange,
    onExpenditureDelete,
    onExpenditureEdit,
  };
};

export const useGetGrantRequest2 = () => {
  const { control, watch, handleSubmit, formState } = useForm();
  const navigate = useNavigate();
  const { userData, userError, userLoading, refetch, isRefetching } =
    useGetUserObject();
  const _userData = userData as AxiosResponse<Partial<IUserModel>>;

  const onSubmit = async (data: any) => {
    try {
      let grantParams: IGrantUpdateParams = {
        id: undefined,
        data: undefined,
      };
      grantParams.id = _userData?.data?.profile?.grant_information?.id;
      grantParams.data = {
        ..._userData?.data?.profile?.grant_information,
        bank: {
          bank_account_name: data.account_name,
          bank_branch: data.bank_branch,
          bank_account_number: data.account_number,
          bank_name: data.bank_name,
        },
      };
      await updateGrantInformation(grantParams);
      navigate('/grants/businessplan/expected_result');
    } catch (e) {
      logError(e);
    }
  };

  return {
    control,
    watch,
    handleSubmit,
    formState,
    onSubmit,
    userData: _userData,
    isLoading: userLoading,
  };
};

export const BankList: OptionType<string, string>[] = [
  {
    label: 'ABSA Bank',
    value: 'absa',
  },
  { label: 'Stanbic Bank', value: 'stanbic' },
];

export const BankBranchList: OptionType<string, string>[] = [
  { label: 'Oyibi Branch', value: 'oyibi_branch' },
];

export const ExpenditureTypeOptions: OptionType<string, string>[] = [
  {
    label: 'Purchase of Machinery/Equipment',
    value: 'capital_expenditure',
  },
  {
    label: 'Purchase of Raw Materials',
    value: 'raw_materials',
  },
  {
    label: 'Rent',
    value: 'rent',
  },
  {
    label: 'Others',
    value: 'others',
  },
];

export const GrantExpenditureTableColumns = [
  'Item',
  'Quantity',
  'Unit Cost',
  'Amount',
];
