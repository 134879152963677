/*eslint-disable*/
import CheckIcon from '@components/buttons/CheckIcon';
import React from 'react';
import { Stack } from 'react-bootstrap';
import line_39 from '../../../assets/line_39.svg';
import propTypes from 'prop-types';
import { ArrowCircleRight } from '@mui/icons-material';
import { defaultBlue } from '@/utils/constants/colors.constants';

export default function ApplicationStatusCard({ data = {}, isMobile = false }) {
  const style = {
    background: 'white',
    borderRadius: '5px',
    alignItems: 'center',
    width: isMobile == true ? '100%' : 'unset',
    paddingTop: '12px',
    paddingRight: '12px',
  };

  const [approvedApplication] = React.useState(true);
  const [approvedGrant, setApprovedGrant] = React.useState(false);
  const [approvedInterview, setApprovedInterview] = React.useState(false);
  const [approvedTraining, setApprovedTraining] = React.useState(false);

  React.useEffect(() => {
    let approvalsArray = data?.approvals;
    approvalsArray.forEach((approval) => {
      console.log(approval?.approval_type, '25555');
      switch (approval?.approval_type) {
        case 'applicant_approval':
          setApprovedInterview(true);
          break;
        case 'interview_approval':
          setApprovedTraining(true);
          break;
        case 'advanced_training_approval':
          setApprovedGrant(true);
          break;
      }
    });
  }, [data]);

  const lineStyle = {
    height: '20px',
  };

  return (
    <div
      className="small-shadow d-flex flex-row justify-content-between py-4 col-md-4"
      style={style}
    >
      <ApplicationStage
        isDone={approvedApplication}
        title="Created Application"
        // stageName="Pending Interview Approval"
        // stageInfo="Awaiting Interview Approval"
        className={'ms-3'}
      />

      {approvedInterview && (
        <>
          {/* <img style={lineStyle} src={line_39} /> */}
          <ArrowCircleRight style={{ color: defaultBlue }} />
          <ApplicationStage
            isDone={approvedTraining}
            title="Interview Stage"
            // stageName="Pending Training Approval"
            // stageInfo="Awaiting Training Approval"
          />
        </>
      )}

      {approvedTraining && (
        <>
          {/* <img style={lineStyle} src={line_39} /> */}
          <ArrowCircleRight style={{ color: defaultBlue }} />
          <ApplicationStage
            isDone={approvedGrant}
            title="Training Stage"
            // stageName="Pending Grant Approval"
            // stageInfo="Awaiting Grant Approval"
          />
        </>
      )}

      {approvedGrant && (
        <>
          {/* <img style={lineStyle} src={line_39} /> */}
          <ArrowCircleRight style={{ color: defaultBlue }} />
          <ApplicationStage
            isDone={false}
            title="Grant Approval Stage"
            // stageName="Pending Grant Disbursement"
            // stageInfo="Awaiting Grant Disbursement"
            className={'me-3'}
          />
        </>
      )}

      <div className="vertical divider"></div>
    </div>
  );
}

ApplicationStatusCard.propTypes = {
  data: propTypes.object,
  isMobile: propTypes.bool,
};

function ApplicationStage({ isDone, title, stageName, stageInfo, className }) {
  return (
    <div gap={2} className={`position-relative ${className}`}>
      {isDone ? (
        <Stack className="position-relative pb-xl-0 mx-xl-auto">
          <h6 className="fw-bold" style={{ color: defaultBlue }}>
            {title}
          </h6>

          <div
            className="pt-2   text-wrap text-break"
            style={{ fontWeight: '600', color: defaultBlue }}
          >
            <CheckIcon /> Stage Complete!
          </div>
        </Stack>
      ) : (
        <Stack gap={2} className="position-relative pb-xl-0 opacity-50 ">
          <h6 className="fw-bold text-muted">{title}</h6>
          {stageName && stageInfo && (
            <>
              <div className="pt-2  px-2 text-wrap text-break">{stageName}</div>
              <div className="ps-3 primary-text text-sm text-wrap ">
                {stageInfo}
              </div>
            </>
          )}
        </Stack>
      )}
    </div>
  );
}

ApplicationStage.propTypes = {
  isDone: propTypes.bool,
  title: propTypes.string,
  stageName: propTypes.string,
  stageInfo: propTypes.string,
  className: propTypes.string,
};
