/*eslint-disable*/
import React from 'react';
import BusinessFormBaseLayout from './BusinessFormBaseLayout';
import { PreviewTab, PreviewTabs } from './PreviewGrantForm';
import { useGetPreviewBusinessPlan } from './services/PreviewBusinessPlan.service';
import { Row, Col } from 'react-bootstrap';
import MainButton from '@/components/buttons/Buttons';
import ApplicationSummary from './components/forms/BusinessPlan/ApplicationSummary';
import ApplicantInformation from './components/forms/BusinessPlan/ApplicantInformation';
import ApplicantInformation2 from './components/forms/BusinessPlan/ApplicantInformation2';
import ApplicantInformation3 from './components/forms/BusinessPlan/ApplicantInformation3';
import ProjectInformation from './components/forms/BusinessPlan/ProjectInformation';
import ProjectInformation2 from './components/forms/BusinessPlan/ProjectInformation2';
import GrantRequest from './components/forms/BusinessPlan/GrantRequest';
import Sustainability from './components/forms/BusinessPlan/Sustainability';
import ApplicantInformation4 from './components/forms/BusinessPlan/ApplicantInformation4';
import Disclaimer from './components/forms/BusinessPlan/Disclaimer';
import GrantRequest2 from './components/forms/BusinessPlan/GrantRequest2';
import ProjectInformation3 from './components/forms/BusinessPlan/ProjectInformation3';
import ProjectInformation4 from './components/forms/BusinessPlan/ProjectInformation4';
import Signature from './components/forms/BusinessPlan/Signature';
import { useNavigate, useLocation } from 'react-router-dom';
import AddButton from './components/AddButton';
import ExpectedResults from './components/forms/BusinessPlan/ExpectedResults';
import { useQueryClient } from 'react-query';
import TextField from '@/components/forms/TextField/TextField';
import { IUserModel } from '@/utils/constants/userModel.interface';

const PreviewBusinessPlanForm = () => {
  const {
    tabValue,
    formStages,
    printState,
    autoPrint,
    setAutoPrint,
    setTabValue,
    handleTabChange,
    printForm,
    setPrintState,
    ...props
  } = useGetPreviewBusinessPlan();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const user = JSON.parse(window.localStorage.getItem('user')) as IUserModel;

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const _autoPrint = queryParams.get('print');
    if (_autoPrint == 'true') {
      setAutoPrint(true);
    }
  }, [location]);

  React.useEffect(() => {}, []);
  return (
    <BusinessFormBaseLayout
      page={7}
      title={!autoPrint ? 'Preview' : 'Print Form'}
    >
      <Row>
        {!autoPrint && (
          <Col md={12} xs={12} style={{ marginBottom: '40px' }}>
            <Row
              style={{
                marginBottom: '15px',
                fontWeight: '700',
                fontStyle: 'italic',
                textAlign: 'center',
                borderBottom: '1px solid #e7e7e7',
                paddingBottom: '10px',
              }}
            >
              <Col md={12} xs={12}>
                *Click on the sections to display form information*
              </Col>
            </Row>
            <PreviewTabs
              value={tabValue}
              onChange={handleTabChange}
              TabIndicatorProps={{ style: { display: 'none' } }}
            >
              {formStages.map((stage, index) => {
                if (
                  user?.skipped_advanced_training == true &&
                  (index == 5 || index == 8)
                ) {
                  return;
                }
                return (
                  <PreviewTab
                    value={stage.value}
                    key={`${index}`}
                    label={stage.label}
                  />
                );
              })}
            </PreviewTabs>
            <Row
              style={{
                marginTop: '15px',
                fontWeight: '700',
                fontStyle: 'italic',
                textAlign: 'center',
                borderTop: '1px solid #e7e7e7',
                paddingTop: '10px',
              }}
            >
              <Col md={12} xs={12}>
                *Scroll to the right to reveal additional sections*
              </Col>
            </Row>
          </Col>
        )}
        {autoPrint == false && (
          <Col md={12} xs={12} sm={12} style={{ marginBottom: '20px' }}>
            <MainButton
              onClick={() => {
                navigate(`${formStages[tabValue].url}`);
              }}
              text="View Page"
            />
          </Col>
        )}

        {autoPrint == true && (
          <Col md={12} xs={12} sm={12} style={{ marginBottom: '20px' }}>
            <AddButton
              text={'Print Form'}
              type={'button'}
              onClick={() => {
                setPrintState('print');
              }}
            />
          </Col>
        )}

        <Col md={12} xs={12} sm={12}>
          {(tabValue == 0 || printState == 'print' || autoPrint == true) && (
            <div
              className="html2pdf__page-break"
              ref={props.applicationSummaryRef}
            >
              {printState == 'print' && (
                <TextField
                  fontSize={18}
                  text={'Ghana Jobs and Skills Project'}
                  className={'fs-5 fw-bold mb-5 text-center'}
                />
              )}

              <ApplicationSummary
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {(tabValue == 1 || printState == 'print' || autoPrint == true) && (
            <div
              className="html2pdf__page-break"
              ref={props.applicantInformationRef}
            >
              <ApplicantInformation
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {(tabValue == 2 || printState == 'print' || autoPrint == true) && (
            <div
              className="html2pdf__page-break"
              ref={props.applicantInformation2Ref}
            >
              <ApplicantInformation2
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {(tabValue == 3 || printState == 'print' || autoPrint == true) && (
            <div
              className="html2pdf__page-break"
              ref={props.applicantInformation3Ref}
            >
              <ApplicantInformation3
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {(tabValue == 4 || printState == 'print' || autoPrint == true) && (
            <div
              className="html2pdf__page-break"
              ref={props.applicantInformation4Ref}
            >
              <ApplicantInformation4
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {(tabValue == 5 || printState == 'print' || autoPrint == true) &&
            user?.skipped_advanced_training == false && (
              <div
                className="html2pdf__page-break"
                ref={props.projectInformationRef}
              >
                <ProjectInformation
                  isPreview={true}
                  isPrint={printState == 'print'}
                />
              </div>
            )}
          {(tabValue == 6 || printState == 'print' || autoPrint == true) && (
            <div
              className="html2pdf__page-break"
              ref={props.projectInformation2Ref}
            >
              <ProjectInformation2
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}

          {(tabValue == 7 || printState == 'print' || autoPrint == true) && (
            <div
              className="html2pdf__page-break"
              ref={props.projectInformation3Ref}
            >
              <ProjectInformation3
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {(tabValue == 8 || printState == 'print' || autoPrint == true) &&
            user?.skipped_advanced_training == false && (
              <div
                className="html2pdf__page-break"
                ref={props.projectInformation4Ref}
              >
                <ProjectInformation4
                  isPreview={true}
                  isPrint={printState == 'print'}
                />
              </div>
            )}
          {(tabValue == 9 || printState == 'print' || autoPrint == true) && (
            <div className="html2pdf__page-break" ref={props.grantRequestRef}>
              <GrantRequest isPreview={true} isPrint={printState == 'print'} />
            </div>
          )}
          {(tabValue == 10 || printState == 'print' || autoPrint == true) && (
            <div className="html2pdf__page-break" ref={props.grantRequest2Ref}>
              <GrantRequest2 isPreview={true} isPrint={printState == 'print'} />
            </div>
          )}
          {(tabValue == 11 || printState == 'print' || autoPrint == true) && (
            <div
              className="html2pdf__page-break"
              ref={props.expectedResultsRef}
            >
              <ExpectedResults
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {(tabValue == 12 || printState == 'print' || autoPrint == true) && (
            <div className="html2pdf__page-break" ref={props.sustainabilityRef}>
              <Sustainability
                isPreview={true}
                isPrint={printState == 'print'}
              />
            </div>
          )}
          {/* {(tabValue == 13 || printState == 'print' || autoPrint == true) && (
            <div className="html2pdf__page-break" ref={props.disclaimerRef}>
              <Disclaimer isPreview={true} isPrint={printState == 'print'} />
            </div>
          )}
          {(tabValue == 14 || printState == 'print' || autoPrint == true) && (
            <div className="html2pdf__page-break" ref={props.signatureRef}>
              <Signature isPreview={true} isPrint={printState == 'print'} />
            </div>
          )} */}
        </Col>
        {!autoPrint && (
          <Row className="mt-5">
            <Col xs={12} style={{ marginBottom: '10px' }} md={4}>
              <MainButton
                text={'Previous'}
                type={'button'}
                variant="outline-secondary"
                size="lg"
                className="fs-6"
                onClick={() => {
                  if (tabValue !== 0) {
                    setTabValue(tabValue - 1);
                    return;
                  }
                  navigate('/grants/businessplan/sustainability');
                }}
              />
            </Col>
            <Col
              xs={12}
              style={{ marginBottom: '10px' }}
              md={{ span: 4, offset: 4 }}
            >
              <MainButton
                text={tabValue == 13 ? 'Finish' : 'Next'}
                type={'button'}
                variant="primary"
                size="lg"
                className="fs-6"
                onClick={() => {
                  navigate('/grants/businessplan/disclaimer');
                }}
              />
            </Col>
          </Row>
        )}
      </Row>
    </BusinessFormBaseLayout>
  );
};

export default PreviewBusinessPlanForm;
